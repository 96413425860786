import { useEffect } from 'react';

import { useIcebreakerTemplatesState } from './selectors';
import { useIcebreakerTemplatesApi } from './use-icebreaker-templates-api';

export const useIcebreakerTemplates = (dialogId: string) => {
  const icebreakerTemplatesState = useIcebreakerTemplatesState(dialogId);
  const { loadIcebreakerTemplates } = useIcebreakerTemplatesApi();

  useEffect(() => {
    if (!icebreakerTemplatesState) {
      loadIcebreakerTemplates(dialogId);
    }
  }, [loadIcebreakerTemplates, dialogId, icebreakerTemplatesState]);

  return icebreakerTemplatesState;
};
