import { IcebreakerMessageTemplateDto } from 'modules/api/dto';

import { IcebreakerMessageTemplate } from './types';

export const mapIcebreakerMessageTemplateDtoToModel = (
  dto: IcebreakerMessageTemplateDto,
): IcebreakerMessageTemplate => ({
  id: dto.id,
  text: dto.text,
  type: dto.type,
});

export const mapIcebreakerMessageTemplatesDtoToModel = (dto: IcebreakerMessageTemplateDto[]) =>
  dto.map(mapIcebreakerMessageTemplateDtoToModel);
