import { combineSlices, configureStore } from '@reduxjs/toolkit';

import { usersSlice } from 'modules/domain/users/model';
import { fastAnswersSlice } from 'modules/domain/fast-answers/model';
import { dialogSlice } from 'modules/domain/dialog/model';
import { dialogMessagesSlice } from 'modules/domain/messages/model';
import { mediaSlice } from 'modules/domain/media/model';
import { dictionariesSlice } from 'modules/domain/dictionaries/model';
import { modalsSlice } from 'modules/domain/modals/model';
import { spotifyMatchSlice } from 'modules/domain/spotify-match/model';
import { earningsSlice } from 'modules/domain/earnings/model';
import { giftsSlice } from 'modules/domain/gifts/model';
import { operatorSlice } from 'modules/domain/operator/model';
import { calendarSlice } from 'modules/domain/calendar/model';
import { chatInputSlice } from 'modules/domain/chat-input/model';
import { audioMessagesSlice } from 'modules/domain/audio/model';
import { messagesSearchSlice } from 'modules/domain/messages-search/model';
import { dialogNotesSlice } from 'modules/domain/notes/model';
import { dialogPreferencesSlice } from 'modules/domain/preferences/model';
import { copilotSlice } from 'modules/domain/copilot/model';
import { icebreakerSlice } from 'modules/domain/icebreaker/model';

const rootReducer = combineSlices(
  usersSlice,
  dialogSlice,
  fastAnswersSlice,
  dialogMessagesSlice,
  mediaSlice,
  dictionariesSlice,
  modalsSlice,
  spotifyMatchSlice,
  earningsSlice,
  giftsSlice,
  operatorSlice,
  calendarSlice,
  chatInputSlice,
  audioMessagesSlice,
  messagesSearchSlice,
  dialogNotesSlice,
  dialogPreferencesSlice,
  copilotSlice,
  icebreakerSlice,
);

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
});
