import { memo, useMemo, useState } from 'react';

import { useIcebreakerTemplates, useSendIcebreakerMessage } from 'modules/domain/icebreaker/hooks';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { Loader } from 'modules/components/loader';
import { IcebreakerMessageTemplate } from 'modules/domain/icebreaker/model';
import { useTypingActive, TypingSource } from 'modules/services';
import { useActiveModalData, useModalActions } from 'modules/domain/modals/hooks';
import { IcebreakerPickerModalData } from 'modules/constants';
import purpurLogo from 'images/purpur-logo-big.svg';

import { CategoriesSidebar, CategoryItem } from './components/categories-sidebar';
import { TemplateMessagesList } from './components/messages-list';
import { groupTemplatesByCategories } from './helpers';
import styles from './styles.module.scss';

export const IcebreakerPickerModalNew = memo(() => {
  const { parent } = useActiveModalData<IcebreakerPickerModalData>();
  const [selectedCategory, setSelectedCategory] = useState<CategoryItem>('all');

  const dialogId = useCurrentDialogId();

  // keep typing indicator active while modal is opened
  useTypingActive(dialogId, TypingSource.IcebreakerPicker);

  const icebreakerTemplates = useIcebreakerTemplates(dialogId);
  const sendIcebreakerMessage = useSendIcebreakerMessage(dialogId);
  const { closeModal } = useModalActions();

  const { templatesByCategories, categories } = useMemo(
    () => groupTemplatesByCategories(icebreakerTemplates),
    [icebreakerTemplates],
  );

  const handleIcebreakerMessageSend = (template: IcebreakerMessageTemplate) => {
    closeModal();
    sendIcebreakerMessage({
      icebreakerType: template.type,
      icebreakerId: template.id,
      icebreakerText: template.text,
      copilotUsed: parent === 'copilot',
    });
  };

  const renderContent = () => {
    if (!templatesByCategories) {
      return (
        <div className={styles.placeholderContainer}>
          <Loader size={60} />
        </div>
      );
    }

    const categoryMessages = templatesByCategories[selectedCategory];

    if (!categoryMessages) {
      return (
        <div className={styles.placeholderContainer}>
          <p>There are no available PurPur questions for this client</p>
        </div>
      );
    }

    return (
      <div className={styles.content}>
        <CategoriesSidebar
          categories={categories}
          selected={selectedCategory}
          onSelect={setSelectedCategory}
        />
        <TemplateMessagesList
          messages={templatesByCategories[selectedCategory]}
          onItemSendClick={handleIcebreakerMessageSend}
        />
        <img className={styles.logo} src={purpurLogo} alt="logo" />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Send PurPur Question</h3>
      <h4 className={styles.subtitle}>
        Discuss with the client on various topics to extend the chat and increase RR!
      </h4>
      {renderContent()}
    </div>
  );
});
