import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/services';

export function useFeaturesApi() {
  const { features: featuresApi } = useApi();
  const { logError } = useLogger('useFeaturesApi');

  const getIcebreakerAvailability = useCallback(
    async (attendeeId: string) => {
      try {
        const { enabled } = await featuresApi.getIcebreakersEnabled(attendeeId);
        return enabled;
      } catch (error) {
        logError('Failed to get icebreakers availability', { error, attendeeId });
      }
      return false;
    },
    [featuresApi, logError],
  );

  return { getIcebreakerAvailability };
}
