import { useMemo } from 'react';

import { useApi } from 'modules/api';

import { TypingTracker } from '../tracker';

export const useTypingTracker = () => {
  const api = useApi();
  const typingTracker = useMemo(() => TypingTracker.shared(api), [api]);

  return typingTracker;
};
