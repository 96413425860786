export enum UserTag {
  CreditsAccountsVip = 'credits.accounts.vip',
  SpotifyConnected = 'phyllo.spotify.connected',
}

export enum UserExperimentTag {
  DialogSearchByIdEnabled = 'search.enabled',
  CommunicationPurposes = 'communication.purposes',
  CopilotSpecialOptions = 'options.attachments',
  /**
   * Used to determine current chat input. If the user has this tag, the chat input is in the copilot mode.
   */
  CopilotOnly = 'copilot.only',
  PurPurUpgrade1 = 'purpur.improve.1',
  PurPurUpgrade2 = 'purpur.improve.2',
}

export enum UserTargetedTag {
  DialogsFirstChat = 'dialogs.first.chat',
}
