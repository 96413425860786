import { useNewIcebreakerMessagesAvailable } from 'modules/domain/icebreaker/hooks';

import { IcebreakerMessageProps as IcebreakerMessagePropsBase } from './types';
import { IcebreakerMessageOld } from './old';
import { IcebreakerMessageNew } from './new';

type IcebreakerMessageProps = IcebreakerMessagePropsBase & {
  dialogId: string;
};

export const IcebreakerMessage = (props: IcebreakerMessageProps) => {
  const { dialogId, ...messageProps } = props;
  const newIcebreakerEnabled = useNewIcebreakerMessagesAvailable(dialogId);

  if (newIcebreakerEnabled) {
    return <IcebreakerMessageNew {...messageProps} />;
  }

  return <IcebreakerMessageOld {...messageProps} />;
};
