import { memo, useEffect, useMemo, useState } from 'react';

import { useAudioMessagesTemplates, useSendAudioMessageTemplate } from 'modules/domain/audio/hooks';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { Loader } from 'modules/components/loader';
import { TypingSource, useTypingActive } from 'modules/services';

import { AudioCategoriesSidebar } from './components/categories-sidebar';
import { AudioMessagesList } from './components/messages-list';
import { groupTemplatesByCategories } from './helpers';
import styles from './styles.module.scss';

export const AudioSelectorModal = memo(() => {
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const dialogId = useCurrentDialogId();

  // keep typing indicator active while audio selector modal is opened
  useTypingActive(dialogId, TypingSource.AudioPicker);

  const { audioMessagesTemplates } = useAudioMessagesTemplates(dialogId);
  const sendMessageTemplate = useSendAudioMessageTemplate(dialogId);

  const { templatesByCategories, categories } = useMemo(
    () => groupTemplatesByCategories(audioMessagesTemplates),
    [audioMessagesTemplates],
  );

  useEffect(() => {
    const [firstCategory] = categories;
    if (firstCategory) {
      setSelectedCategory(firstCategory);
    }
  }, [categories]);

  const renderContent = () => {
    if (!templatesByCategories) {
      return (
        <div className={styles.placeholderContainer}>
          <Loader size={60} />
        </div>
      );
    }

    const categoryMessages = templatesByCategories[selectedCategory];

    if (!categoryMessages) {
      return (
        <div className={styles.placeholderContainer}>
          <p>There are no available voice messages for this client</p>
        </div>
      );
    }

    return (
      <div className={styles.content}>
        <AudioCategoriesSidebar
          categories={categories}
          selected={selectedCategory}
          onSelect={setSelectedCategory}
        />
        <AudioMessagesList
          messages={templatesByCategories[selectedCategory]}
          onItemSendClick={sendMessageTemplate}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Send Voice Message</h4>
      {renderContent()}
    </div>
  );
});
