import classNames from 'classnames';

import { IcebreakerType } from 'modules/domain/icebreaker/model';

import styles from './styles.module.scss';

export type CategoryItem = 'all' | IcebreakerType.Erotic | IcebreakerType.General;

type Props = {
  categories: CategoryItem[];
  selected: CategoryItem;
  onSelect: (item: CategoryItem) => void;
};

const categoriesKeyToTitleMap: Record<CategoryItem, string> = {
  all: 'All Questions',
  [IcebreakerType.General]: 'Normal Questions',
  [IcebreakerType.Erotic]: 'Spicy Questions',
};

export const CategoriesSidebar = (props: Props) => {
  const { categories, selected, onSelect } = props;

  return (
    <div className={styles.sidebar}>
      <div className={styles.inner}>
        {categories.map(item => (
          <button
            className={classNames(styles.item, {
              [styles.selected]: item === selected,
            })}
            key={item}
            onClick={() => onSelect(item)}
          >
            {categoriesKeyToTitleMap[item]}
          </button>
        ))}
      </div>
    </div>
  );
};
