import { useIcebreakerMessagesAvailableState } from 'modules/domain/icebreaker/hooks/selectors/use-icebreaker-messages-available-state';
import { IcebreakerMessagesAvailable } from 'modules/domain/icebreaker/model';

export const useIcebreakerMessagesAvailable = (dialogId: string) => {
  const icebreakerMessagesAvailableState = useIcebreakerMessagesAvailableState(dialogId);

  const icebreakerMessagesAvailable =
    icebreakerMessagesAvailableState === IcebreakerMessagesAvailable.New ||
    icebreakerMessagesAvailableState === IcebreakerMessagesAvailable.Old;

  return icebreakerMessagesAvailable;
};
