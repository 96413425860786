import { getIcebreakerMessagesAvailable } from 'modules/domain/icebreaker/model';
import { useRootSelector } from 'modules/hooks/redux';

export const useIcebreakerMessagesAvailableState = (dialogId: string) => {
  const icebreakerMessagesAvailableState = useRootSelector(state =>
    getIcebreakerMessagesAvailable(state, dialogId),
  );

  return icebreakerMessagesAvailableState;
};
