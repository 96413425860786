import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IIcebreakerDomain } from './contracts';
import { IcebreakerMessageTemplateDto } from './dto';

export class IcebreakerDomain implements IIcebreakerDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getIcebreakerMessageTemplates(
    animatorId: string,
    attendeeId: string,
  ): Promise<IcebreakerMessageTemplateDto[]> {
    return this.transport
      .get<
        IcebreakerMessageTemplateDto[]
      >(`/multichat/dialogs/${animatorId}/${attendeeId}/purpur/list`)
      .then(response => response.data);
  }
}
