import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { parseDialogId } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';
import { useLogger } from 'modules/services';

import {
  mapIcebreakerMessageTemplatesDtoToModel,
  setIcebreakerTemplates as setIcebreakerTemplatesAction,
} from '../model';

export const useIcebreakerTemplatesApi = () => {
  const { icebreaker: icebreakerApi } = useApi();
  const { logError } = useLogger('useIcebreakerTemplatesApi');
  const setIcebreakerTemplates = useDispatchAction(setIcebreakerTemplatesAction);

  const loadIcebreakerTemplates = useCallback(
    async (dialogId: string) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);
      try {
        const templatesDto = await icebreakerApi.getIcebreakerMessageTemplates(
          animatorId,
          attendeeId,
        );
        const templates = mapIcebreakerMessageTemplatesDtoToModel(templatesDto);
        setIcebreakerTemplates({ dialogId, templates });
      } catch (error) {
        logError('Failed to load icebreaker templates', { error, animatorId, attendeeId });
      }
    },
    [icebreakerApi, logError, setIcebreakerTemplates],
  );

  return {
    loadIcebreakerTemplates,
  };
};
