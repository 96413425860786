import { ChangeEvent, KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';

import { useDialogLatestMessage } from 'modules/domain/messages/hooks';
import { SmilePicker } from 'modules/components/common';
import { testId } from 'modules/utils';
import {
  useChatInputActions,
  useChatInputText,
  useIsChatInputCopilotOnlyMode,
} from 'modules/domain/chat-input/hooks';

import { TypedMessageIndicator } from './components/typed-message-indicator';
import { PickerButtons } from './components/picker-buttons';
import { uncapsTheLastWord } from './utils';
import {
  useSendTextMessage,
  useTypedMessageIndicator,
  useChatInputTypingTrackerHandler,
  useChatInputFocuser,
  useValidateTextMessage,
} from './hooks';
import styles from './index.module.scss';

type Props = {
  dialogId: string;
};

const SEND_BTN_KEY = 'Enter';

export const ChatForm = memo((props: Props) => {
  const { dialogId } = props;

  const [smilesPickerIsVisible, setSmilesPickerVisible] = useState(false);

  const inputText = useChatInputText();
  const { setInputFocused, setInputText, resetInputText } = useChatInputActions();
  const latestMessage = useDialogLatestMessage(dialogId);
  const indicator = useTypedMessageIndicator({
    messageText: inputText,
    lastDialogMessage: latestMessage,
    dialogId,
  });
  const handleTypingChange = useChatInputTypingTrackerHandler(dialogId, SEND_BTN_KEY);
  const validateTextMessage = useValidateTextMessage(latestMessage, indicator.status);
  const sendTextMessage = useSendTextMessage(dialogId);

  const isCopilotOnlyMode = useIsChatInputCopilotOnlyMode(dialogId);

  useEffect(() => {
    resetInputText();
  }, [dialogId, resetInputText]);

  const inputRef = useRef<HTMLInputElement>(null);

  useChatInputFocuser(dialogId, inputRef);

  const handleSendPress = () => {
    const message = inputText.trim();
    if (!message) return;

    const isValid = validateTextMessage(message);
    if (!isValid) return;

    sendTextMessage(message);
    resetInputText();
  };

  const handleInputKeyPress = (
    event: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>,
  ) => {
    handleTypingChange(event);

    switch (event.key) {
      case ' ':
        setInputText(uncapsTheLastWord(event.target.value));
        break;

      case SEND_BTN_KEY:
        handleSendPress();
        break;
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleSmileSelect = useCallback(
    (emoji: string) => {
      setInputText(emoji, true);
    },
    [setInputText],
  );

  const toggleSmilesVisibility = useCallback(
    (visible: boolean) => setSmilesPickerVisible(!visible),
    [],
  );

  return (
    <div className={styles.container}>
      {!isCopilotOnlyMode && (
        <SmilePicker
          isVisible={smilesPickerIsVisible}
          onSelect={handleSmileSelect}
          onToggleVisibility={toggleSmilesVisibility}
        />
      )}
      <input
        disabled={isCopilotOnlyMode}
        {...testId('send-message-input')}
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="Type a message..."
        onKeyUp={handleInputKeyPress}
        onChange={handleInputChange}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        value={isCopilotOnlyMode ? 'Use copilot to write a text message ☝️' : inputText}
      />
      <TypedMessageIndicator status={indicator.status} percent={indicator.percent} />
      <PickerButtons dialogId={dialogId} />
    </div>
  );
});
