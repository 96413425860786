export enum ModalNames {
  FastAnswers = 'FastAnswers',
  SendComplaint = 'SendComplaint',
  MediaSelector = 'MediaSelector',
  WebcamMedia = 'WebcamMedia',
  DialogSearch = 'DialogSearch',
  Calendar = 'Calendar',
  AudioSelector = 'AudioSelector',
  IcebreakerPicker = 'IcebreakerPicker',
}

export type ModalsModelState = {
  activeModalName: ModalNames | null;
  activeModalData?: any;
};
