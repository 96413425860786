import { memo } from 'react';

import { MediaSelectorButton } from 'modules/components/chat/media-selector/button';
import { GiftsPicker } from 'modules/components/gift-picker';
import { useDialogMediaEnabled } from 'modules/domain/media/hooks';
import { useAudioMessagesAvailable } from 'modules/domain/features/hooks';
import { AudioSelectorButton } from 'modules/components/chat/audio/selector/button';
import { IcebreakerPicker } from 'modules/components/icebreaker-picker';
import { useIcebreakerMessagesAvailable } from 'modules/domain/icebreaker/hooks';

import styles from './styles.module.scss';

type Props = {
  dialogId: string;
};

export const PickerButtons = memo((props: Props) => {
  const { dialogId } = props;

  const mediaInChatEnabled = useDialogMediaEnabled(dialogId);
  const icebreakersAvailable = useIcebreakerMessagesAvailable(dialogId);
  const audioMessagesAvailable = useAudioMessagesAvailable(dialogId);

  return (
    <div className={styles.buttons}>
      {audioMessagesAvailable && <AudioSelectorButton dialogId={dialogId} />}
      <GiftsPicker parent="chat" dialogId={dialogId} />
      {mediaInChatEnabled && <MediaSelectorButton parent="chat" dialogId={dialogId} />}
      {icebreakersAvailable && (
        <IcebreakerPicker
          newPickerClassName={styles.icebreakerPickerNew}
          parent="chat"
          dialogId={dialogId}
        />
      )}
    </div>
  );
});
