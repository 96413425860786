import { DialogMessageMetaDto } from 'modules/api/dto';
import { useSendDialogMessage } from 'modules/domain/messages/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { MessageType } from 'modules/domain/messages/model';

import { useUnansweredUnpaidMessageCheck } from './use-unanswered-unpaid-message-check';

const UNANSWERED_UNPAID_MESSAGE_REASON = 'possible topic';

export const useSendTextMessage = (dialogId: string) => {
  const sendDialogMessage = useSendDialogMessage(dialogId);
  const { shouldAnswerUnansweredUnpaidMessage, setUnansweredUnpaidMessageAnswered } =
    useUnansweredUnpaidMessageCheck(dialogId);

  const sendTextMessage = (message: string) => {
    const messageMeta: DialogMessageMetaDto = {};

    if (shouldAnswerUnansweredUnpaidMessage) {
      messageMeta.reason = UNANSWERED_UNPAID_MESSAGE_REASON;

      setUnansweredUnpaidMessageAnswered();
    }

    sendDialogMessage({ text: message, meta: messageMeta, type: MessageType.TEXT }).catch(() => {
      showChatErrorToast(
        `Failed to send a message: "${message}". Please check your connection and try again later`,
      );
    });
  };

  return sendTextMessage;
};
