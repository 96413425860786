import { useCopilotDataState, useCopilotLastUsageData } from 'modules/domain/copilot/hooks';
import { checkCopilotLoaded } from 'modules/domain/copilot/hooks/use-copilot-analytics/helpers';
import { useDialogLatestMessage } from 'modules/domain/messages/hooks';
import { UserExperimentTag, useUserHasExperimentTag } from 'modules/domain/users/hooks';
import { parseDialogId } from 'modules/utils';

export const useIsChatInputCopilotOnlyMode = (dialogId: string) => {
  const { animatorId, attendeeId } = parseDialogId(dialogId);
  const hasCopilotOnlyTag = useUserHasExperimentTag(attendeeId, UserExperimentTag.CopilotOnly);

  const latestMessage = useDialogLatestMessage(dialogId);
  const copilotLastUsageData = useCopilotLastUsageData();
  const copilotData = useCopilotDataState(animatorId, attendeeId, latestMessage?.id || 0);

  const isCopilotOnlyMode =
    hasCopilotOnlyTag && checkCopilotLoaded(copilotData) && !copilotLastUsageData;

  return isCopilotOnlyMode;
};
