import classNames from 'classnames';

import { useNewIcebreakerMessagesAvailable } from 'modules/domain/icebreaker/hooks';

import { IcebreakerPickerProps as IcebreakerPickerPropsBase } from './types';
import { IcebreakerPickerNew } from './new';
import { IcebreakerPickerOld } from './old';

type IcebreakerPickerProps = IcebreakerPickerPropsBase & {
  newPickerClassName?: string;
};

export const IcebreakerPicker = (props: IcebreakerPickerProps) => {
  const { newPickerClassName, btnClassName, ...pickerProps } = props;
  const newIcebreakerEnabled = useNewIcebreakerMessagesAvailable(props.dialogId);

  if (newIcebreakerEnabled) {
    return (
      <IcebreakerPickerNew
        btnClassName={classNames(newPickerClassName, btnClassName)}
        {...pickerProps}
      />
    );
  }

  return <IcebreakerPickerOld btnClassName={btnClassName} {...pickerProps} />;
};
