import { useEffect } from 'react';

import { useUserExperimentTags } from 'modules/domain/users/hooks';
import { useDispatchAction } from 'modules/hooks/redux';
import { checkNewIcebreakerEnabled } from 'modules/domain/icebreaker/helpers';
import { useFeaturesApi, useIcebreakersEnabled } from 'modules/domain/features/hooks';
import {
  IcebreakerMessagesAvailable,
  setIcebreakerMessagesAvailable as setIcebreakerMessagesAvailableAction,
} from 'modules/domain/icebreaker/model';
import { Dialog } from 'modules/domain/dialog/model';

export function useIcebreakersAvailabilityLoader(currentDialog: Dialog) {
  const { attendeeId, id: dialogId } = currentDialog;
  const icebreakersEnabled = useIcebreakersEnabled(dialogId);
  const setIcebreakerMessagesAvailable = useDispatchAction(setIcebreakerMessagesAvailableAction);
  const { getIcebreakerAvailability } = useFeaturesApi();
  const attendeeExperimentTags = useUserExperimentTags(attendeeId);

  useEffect(() => {
    if (!icebreakersEnabled) {
      // icebreakers are disabled. No need to check availability
      setIcebreakerMessagesAvailable({ dialogId, available: IcebreakerMessagesAvailable.None });
      return;
    }

    if (!attendeeExperimentTags) {
      // wait until attendee experiment tags are loaded
      return;
    }

    const newIcebreakerEnabled = checkNewIcebreakerEnabled(attendeeExperimentTags);

    getIcebreakerAvailability(attendeeId).then(available => {
      let availableType = newIcebreakerEnabled
        ? IcebreakerMessagesAvailable.New
        : IcebreakerMessagesAvailable.Old;

      if (!available) {
        availableType = IcebreakerMessagesAvailable.None;
      }

      setIcebreakerMessagesAvailable({ dialogId, available: availableType });
    });
  }, [
    icebreakersEnabled,
    dialogId,
    attendeeExperimentTags,
    attendeeId,
    getIcebreakerAvailability,
    setIcebreakerMessagesAvailable,
  ]);

  return icebreakersEnabled;
}
