import classNames from 'classnames';

import { testId } from 'modules/utils';
import { Dialog } from 'modules/domain/dialog/model';
import { UserPanel } from 'modules/components/user/user-panel';
import { ChatHeader } from 'modules/components/chat';
import { ChatForm } from 'modules/components/chat-form';
import { useDialogMessages } from 'modules/domain/messages/hooks';
import { PageLoader } from 'modules/components/page-loader';

import { useFirstInvisibleMessageTimestamp, useChatServices, useDialogReady } from './hooks';
import { ChatLog } from './chat-log';
import styles from './styles.module.scss';

type Props = {
  currentDialog: Dialog;
};

export const ChatWrapper = (props: Props) => {
  const { currentDialog } = props;
  const { animatorId, attendeeId, id: currentDialogId } = currentDialog;

  useChatServices(currentDialog);

  const dialogReady = useDialogReady(currentDialog);
  const messages = useDialogMessages(currentDialogId);
  const firstInvisibleMessageTimestamp = useFirstInvisibleMessageTimestamp(messages);

  if (!dialogReady) {
    return <PageLoader />;
  }

  return (
    <div className={styles.chatWrapper} key={currentDialogId}>
      <div className={styles.chatMiddle}>
        <div className={styles.chatBlock}>
          <div
            {...testId('chat-interlocutor-left')}
            className={classNames(styles.chatInterlocutor, styles.left)}
          >
            <UserPanel type="attendee" animatorId={animatorId} attendeeId={attendeeId} />
          </div>
          <div className={styles.chatLogWrapper} {...testId('chat-log-wrapper')}>
            <ChatHeader dialogId={currentDialogId} timestamp={firstInvisibleMessageTimestamp} />
            <ChatLog
              messages={messages}
              currentDialogId={currentDialogId}
              animatorId={animatorId}
              attendeeId={attendeeId}
            />
            <ChatForm dialogId={currentDialogId} />
          </div>
          <div
            {...testId('chat-interlocutor-right')}
            className={classNames(styles.chatInterlocutor, styles.right)}
          >
            <UserPanel type="animator" animatorId={animatorId} attendeeId={attendeeId} />
          </div>
        </div>
      </div>
    </div>
  );
};
