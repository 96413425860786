import { IcebreakerType } from 'modules/api/dto';
import { useSendDialogMessage } from 'modules/domain/messages/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { MessageType } from 'modules/domain/messages/model';
import { useIcebreakerTemplatesApi } from 'modules/domain/icebreaker/hooks/use-icebreaker-templates-api';

export type SendIcebreakerMessageParams = {
  icebreakerType: IcebreakerType;
  // icebreakerId and icebreakerText are used in new icebreaker picker
  icebreakerText?: string;
  icebreakerId?: number;
  copilotUsed: boolean;
};

export const useSendIcebreakerMessage = (dialogId: string) => {
  const sendDialogMessage = useSendDialogMessage(dialogId);
  const { loadIcebreakerTemplates } = useIcebreakerTemplatesApi();

  const sendIcebreakerMessage = async (params: SendIcebreakerMessageParams) => {
    const { icebreakerType, icebreakerId, icebreakerText, copilotUsed } = params;
    try {
      await sendDialogMessage({
        text: 'Sent you a PurPur Card',
        meta: { icebreakerType, icebreakerText, icebreakerId },
        type: MessageType.ICEBREAKER,
        copilotUsed,
      });

      if (icebreakerId !== undefined) {
        // reload templates to mark the sent one as used
        // now used with condition because of the new and old icebreaker pickers
        // TODO: remove the condition after the old icebreaker picker is removed
        loadIcebreakerTemplates(dialogId);
      }
    } catch (error) {
      showChatErrorToast(
        'Failed to send a PurPur Card. Please check your connection and try again later',
      );
    }
  };

  return sendIcebreakerMessage;
};
