import { shuffle } from 'lodash';

import { IcebreakerMessageTemplate, IcebreakerType } from 'modules/domain/icebreaker/model';

import { CategoryItem } from './components/categories-sidebar';

type TemplatesByCategories = Record<CategoryItem, IcebreakerMessageTemplate[]>;

type GroupTemplatesByCategoriesResult = {
  templatesByCategories: TemplatesByCategories | null;
  categories: CategoryItem[];
};

export const groupTemplatesByCategories = (
  templates: IcebreakerMessageTemplate[] | undefined,
): GroupTemplatesByCategoriesResult => {
  if (!templates) {
    return {
      templatesByCategories: null,
      categories: [],
    };
  }

  const templatesByCategories: TemplatesByCategories = {
    all: templates,
    [IcebreakerType.General]: [],
    [IcebreakerType.Erotic]: [],
  };

  templates.forEach(template => {
    templatesByCategories[template.type].push(template);
  });

  const categories = Object.keys(templatesByCategories) as CategoryItem[];

  categories.forEach(key => {
    // sort items in random order
    templatesByCategories[key] = shuffle(templatesByCategories[key]);
  });

  return { templatesByCategories, categories };
};
