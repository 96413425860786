import purpurPickerIcon from 'images/purpur-picker-icon-new.svg';
import { testId } from 'modules/utils';
import { IconButton } from 'modules/components/common/button';
import { useModalActions } from 'modules/domain/modals/hooks';
import { ModalNames } from 'modules/domain/modals/model';
import { IcebreakerPickerModalData } from 'modules/constants';

import { IcebreakerPickerProps } from '../types';

export const IcebreakerPickerNew = (props: IcebreakerPickerProps) => {
  const { btnRef, btnClassName, parent } = props;
  const { openModal } = useModalActions();

  const handleBtnClick = () => {
    const modalData: IcebreakerPickerModalData = { parent };
    openModal(ModalNames.IcebreakerPicker, modalData);
  };

  return (
    <IconButton
      ref={btnRef}
      className={btnClassName}
      {...testId('icebreaker-picker')}
      onClick={handleBtnClick}
    >
      <img src={purpurPickerIcon} />
    </IconButton>
  );
};
