import cn from 'classnames';

import { Loader } from 'modules/components/loader';
import purpurLogo from 'images/purpur-logo-small.svg';
import { IcebreakerType } from 'modules/domain/icebreaker/model';
import { testId } from 'modules/utils';

import { IcebreakerMessageProps } from '../types';

import styles from './styles.module.scss';

export function IcebreakerMessageOld(props: IcebreakerMessageProps) {
  const { content, outgoing } = props;
  const { type, text } = content;

  return (
    <div
      {...testId(`icebreaker-message-card-${type}`)}
      className={cn(styles.container, outgoing ? styles.right : styles.left)}
    >
      <div
        className={cn(
          styles.outerBorder,
          type === IcebreakerType.Erotic ? styles.erotic : styles.general,
        )}
      >
        <div className={styles.card}>
          <div className={styles.text}>{text || <Loader />}</div>
          <img src={purpurLogo} className={styles.logo} />
        </div>
      </div>
      <div className={cn(styles.subtext, outgoing ? styles.right : styles.left)}>
        Don&apos;t miss the chance to deepen your connection with
        <br /> a meaningful discussion of the question!
      </div>
    </div>
  );
}
