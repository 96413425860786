import { useEffect } from 'react';

import { TypingSource } from '../tracker';

import { useTypingTracker } from './use-typing-tracker';

export const useTypingActive = (dialogId: string, source: TypingSource) => {
  const typingTracker = useTypingTracker();

  useEffect(() => {
    typingTracker.setTypingStart(dialogId, source);

    return () => {
      typingTracker.setTypingEnd(dialogId, source);
    };
  }, [typingTracker, dialogId, source]);
};
