import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { setInputText } from 'modules/domain/chat-input/model';

import { CopilotData, CopilotModelState, CopilotOption, CopilotUsage } from './types';

type SetCopilotDataAction = PayloadAction<{ dataId: string; copilotData: CopilotData }>;
type SetCopilotDataLoadingAction = PayloadAction<{ dataId: string }>;
type SetCopilotTextOptionSelectedAction = PayloadAction<{
  copilotData: CopilotData;
  option: CopilotOption;
}>;

const initialState: CopilotModelState = {
  lastUsage: null,
  data: {},
};

const onTextInputChanged = (state: CopilotModelState, action: ReturnType<typeof setInputText>) => {
  const { inputText } = action.payload;

  const { lastUsage } = state;
  if (
    lastUsage &&
    lastUsage.type !== CopilotUsage.Replaced &&
    inputText !== lastUsage.option.text
  ) {
    lastUsage.type =
      // If the user has typed less than 3 characters, we consider it as a replacement
      inputText.length < 3 ? CopilotUsage.Replaced : CopilotUsage.Edited;
  }
};

export const copilotModel = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    setCopilotDataLoadingStart: (state, { payload: { dataId } }: SetCopilotDataLoadingAction) => {
      state.data[dataId] = { loading: true };
    },
    setCopilotDataLoadingFailed: (state, { payload: { dataId } }: SetCopilotDataLoadingAction) => {
      state.data[dataId] = { loading: false };
    },
    setCopilotData: (state, { payload: { dataId, copilotData } }: SetCopilotDataAction) => {
      state.data[dataId] = copilotData;
    },
    resetCopilotData: state => {
      state.data = initialState.data;
    },
    resetCopilotLastUsage: state => {
      state.lastUsage = initialState.lastUsage;
    },
    setCopilotTextOptionSelected: (state, action: SetCopilotTextOptionSelectedAction) => {
      const { option, copilotData } = action.payload;

      state.lastUsage = {
        type: CopilotUsage.Used,
        option,
        copilotData,
      };
    },
  },
  extraReducers: builder => {
    addResetDialogStateReducer(() => initialState)(builder);
    builder.addCase(setInputText, onTextInputChanged);
  },
  selectors: {
    getCopilotData: (state, dataId: string) => state.data[dataId],
    getCopilotLastUsageData: state => state.lastUsage,
  },
});
