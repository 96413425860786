import { useState } from 'react';

import purpurPickerIcon from 'images/purpur-picker-icon.svg';
import { testId } from 'modules/utils';
import { IconButton } from 'modules/components/common/button';
import { IcebreakerPickerModalOld } from 'modules/components/icebreaker-picker-modal/old';

import { IcebreakerPickerProps } from '../types';

import styles from './styles.module.scss';

export const IcebreakerPickerOld = (props: IcebreakerPickerProps) => {
  const { dialogId, btnRef, btnClassName, modalClassName, parent } = props;

  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <div className={styles.pickerContainer}>
      {pickerOpen && (
        <IcebreakerPickerModalOld
          parent={parent}
          modalClassName={modalClassName}
          dialogId={dialogId}
          onClose={() => setPickerOpen(false)}
        />
      )}
      <IconButton
        ref={btnRef}
        className={btnClassName}
        {...testId('icebreaker-picker')}
        onClick={() => setPickerOpen(pickerOpen => !pickerOpen)}
      >
        <img src={purpurPickerIcon} />
      </IconButton>
    </div>
  );
};
