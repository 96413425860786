import { memo } from 'react';
import cn from 'classnames';

import { IcebreakerMessageTemplate, IcebreakerType } from 'modules/domain/icebreaker/model';
import { testId } from 'modules/utils';

import styles from './styles.module.scss';

type Props = Pick<IcebreakerMessageTemplate, 'text' | 'type'> & {
  onSendClick: () => void;
};

export const TemplateMessageItem = memo((props: Props) => {
  const { text, type, onSendClick } = props;

  return (
    <div
      {...testId(`icebreaker-picker-message-${type}-new`)}
      className={cn(styles.item, { [styles.erotic]: type === IcebreakerType.Erotic })}
    >
      <p className={styles.text}>{text}</p>
      <button className={styles.btn} onClick={onSendClick}>
        Send
      </button>
    </div>
  );
});
