import { memo } from 'react';

import {
  useCurrentDialog,
  useLockedDialogAutoSwitcher,
  useNoNewDialogs,
} from 'modules/domain/dialog/hooks';
import { useOperatorActivitySender } from 'modules/domain/operator/hooks';

import { ChatWithEmptyStates } from './chat-with-empty-states';
import styles from './styles.module.scss';

export const MultichatWindow = memo(() => {
  useOperatorActivitySender();
  useLockedDialogAutoSwitcher();

  const dialog = useCurrentDialog();
  const noNewChats = useNoNewDialogs();

  return (
    <div className={styles.multichatWindow}>
      <ChatWithEmptyStates noNewChats={noNewChats} dialog={dialog} />
    </div>
  );
});
