import { createSlice } from '@reduxjs/toolkit';

import { addResetDialogStateReducer } from 'modules/domain/dialog/model';
import { ActionWithDialogId } from 'modules/domain/common/types';

import {
  IcebreakerMessagesAvailable,
  IcebreakerMessageTemplate,
  IcebreakerModelState,
} from './types';

type SetIcebreakerTemplatesAction = ActionWithDialogId<{
  templates: IcebreakerMessageTemplate[];
}>;
type SetIcebreakersMessagesAvailableAction = ActionWithDialogId<{
  available: IcebreakerMessagesAvailable;
}>;

const initialState: IcebreakerModelState = {};

export const icebreakerModel = createSlice({
  name: 'icebreaker',
  initialState,
  reducers: {
    setIcebreakerTemplates: (state, action: SetIcebreakerTemplatesAction) => {
      const { templates, dialogId } = action.payload;

      if (state[dialogId]) {
        state[dialogId].templates = templates;
      }
    },
    setIcebreakerMessagesAvailable: (state, action: SetIcebreakersMessagesAvailableAction) => {
      const { available, dialogId } = action.payload;
      state[dialogId] = { available };
    },
  },
  extraReducers: addResetDialogStateReducer(() => initialState),
  selectors: {
    getIcebreakerTemplates: (state, dialogId: string) => state[dialogId]?.templates,
    getIcebreakerMessagesAvailable: (state, dialogId: string) => state[dialogId]?.available,
  },
});
