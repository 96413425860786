import { IDRecord } from 'modules/domain/common/types';
import { IcebreakerType } from 'modules/api/dto';

export { IcebreakerType };

export type IcebreakerMessageTemplate = {
  id: number;
  text: string;
  type: IcebreakerType;
};

export enum IcebreakerMessagesAvailable {
  Old = 'old',
  New = 'new',
  None = 'none',
}

export type DialogIcebreakerState = {
  available: IcebreakerMessagesAvailable;
  templates?: IcebreakerMessageTemplate[];
};

export type IcebreakerModelState = IDRecord<DialogIcebreakerState>;
