import { IcebreakerType } from 'modules/domain/icebreaker/model';
import { IDRecord } from 'modules/domain/common/types';
import {
  DialogMessageMetaDto,
  MessageDeliveryStatus,
  AudioTranscriptionDto,
} from 'modules/api/dto';

export enum MessageType {
  TEXT = 'text',
  GIFT = 'cheer', // `cheer` is used on the api side
  PHOTO = 'photo',
  ICEBREAKER = 'icebreaker',
  AUDIO = 'audio',
}

export type BasenameContent = {
  basename: string;
};

export type ReferenceContent = {
  reference?: string;
};

export type LocalPhotoContent = {
  localFilePath: string;
};

export type IcebreakerContent = {
  text: string;
  type: IcebreakerType;
};

export type GiftMessageContent = BasenameContent & {
  isAnimated?: boolean;
};

export type AudioMessageContent = BasenameContent & {
  transcription?: AudioTranscriptionDto;
};

export type DialogMessageContent =
  | string
  | BasenameContent
  | LocalPhotoContent
  | IcebreakerContent
  | GiftMessageContent
  | AudioMessageContent;

export type DialogMessage = {
  id: number;
  meta: DialogMessageMetaDto;
  outgoing: boolean;
  read: boolean;
  recipientId: string;
  senderId: string;
  tag: string;
  timestamp: number;
  status?: MessageDeliveryStatus;
  type: MessageType;
  content: DialogMessageContent;
};

export type SendDialogMessageBaseData = {
  text?: string;
  file?: File;
  meta?: DialogMessageMetaDto;
};

export type SendDialogMessageAnalyticsData = {
  copilotUsed?: boolean;
  type: MessageType;
};

export type SendDialogMessageData = SendDialogMessageBaseData & SendDialogMessageAnalyticsData;

export type UnansweredUnpaidMessagesState = {
  hasMessages: boolean;
  messageLoaded?: boolean;
  messageAnswered?: boolean;
};

export type DialogMessageMediaUploadState = {
  progress: number;
};

export type DialogMessagesMediaUploadState = IDRecord<DialogMessageMediaUploadState>;

export enum DialogMessagesAllowed {
  All = 'all',
  TextAndAudio = 'TextAndAudio',
}

export type DialogMessagesInitState = {
  unansweredUnpaid: UnansweredUnpaidMessagesState;
  messagesAllowed: DialogMessagesAllowed;
};

export type DialogMessagesState = DialogMessagesInitState & {
  messages: DialogMessage[];
  messagesMediaUpload: DialogMessagesMediaUploadState;
  someMessagesLoaded: boolean;
  allMessagesLoaded: boolean;
};

export type DialogMessagesModelState = IDRecord<DialogMessagesState>;
