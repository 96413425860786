import { Loader } from 'modules/components/loader';

import styles from './styles.module.scss';

export const PageLoader = () => {
  return (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );
};
