import { useCallback } from 'react';

import { replaceByMask } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';
import { useCurrentDialogId, useCurrentDialogUsersIds } from 'modules/domain/dialog/hooks';
import { useUserField } from 'modules/domain/users/hooks';
import {
  useChatInputActions,
  useIsChatInputCopilotOnlyMode,
} from 'modules/domain/chat-input/hooks';

import { FastAnswer, setFastAnswerSelected as setFastAnswerSelectedAction } from '../model';

export const useFastAnswerSelectAction = () => {
  const dialogId = useCurrentDialogId();
  const { attendeeId } = useCurrentDialogUsersIds();
  const attendeeName = useUserField(attendeeId, 'name');
  const { setInputText } = useChatInputActions();
  const setFastAnswerSelected = useDispatchAction(setFastAnswerSelectedAction);
  const isCopilotOnlyMode = useIsChatInputCopilotOnlyMode(dialogId);

  const selectFastAnswer = useCallback(
    (fastAnswer: FastAnswer) => {
      if (isCopilotOnlyMode) {
        return;
      }

      const message = replaceByMask(fastAnswer.phrase, '%name%', attendeeName || '');
      setInputText(message, true);
      setFastAnswerSelected({ fastAnswer });
    },
    [setFastAnswerSelected, setInputText, attendeeName, isCopilotOnlyMode],
  );

  return selectFastAnswer;
};
