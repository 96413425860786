import cn from 'classnames';

import purpurLogo from 'images/purpur-logo-big.svg';
import purpurImageGeneral from 'images/purpur-image-general.svg';
import purpurImageErotic from 'images/purpur-image-erotic.svg';
import { useClickOutside } from 'modules/domain/common/hooks';
import { IcebreakerType } from 'modules/api/dto';
import { useSendIcebreakerMessage } from 'modules/domain/icebreaker/hooks';
import { MediaPickerParent } from 'modules/constants';
import { testId } from 'modules/utils';

import styles from './styles.module.scss';

type Props = {
  dialogId: string;
  modalClassName?: string;
  parent: MediaPickerParent;
  onClose: () => void;
};

export const IcebreakerPickerModalOld = (props: Props) => {
  const { dialogId, modalClassName, parent, onClose } = props;

  const sendIcebreakerMessage = useSendIcebreakerMessage(dialogId);

  const pickerModalRef = useClickOutside<HTMLDivElement>({
    condition: true,
    action: onClose,
  });

  const onPickIcebreaker = (icebreakerType: IcebreakerType) => {
    onClose();

    sendIcebreakerMessage({ icebreakerType, copilotUsed: parent === 'copilot' });
  };

  return (
    <div className={cn(styles.modal, modalClassName)} ref={pickerModalRef}>
      <div className={styles.title}>
        Let&apos;s play <img src={purpurLogo} alt="purpur" />
      </div>
      <div className={styles.subtitle}>
        Send a PurPur Card to ask your partner a question and answer yourself once you get their
        reply
      </div>
      <div className={styles.images}>
        <div
          {...testId(`icebreaker-picker-message-${IcebreakerType.Erotic}`)}
          className={styles.eroticCard}
          onClick={() => onPickIcebreaker(IcebreakerType.Erotic)}
        >
          <img
            className={cn(styles.purpurImage, styles.eroticImage)}
            src={purpurImageErotic}
            alt="erotic"
          />
        </div>
        <div
          {...testId(`icebreaker-picker-message-${IcebreakerType.General}`)}
          className={styles.generalCard}
          onClick={() => onPickIcebreaker(IcebreakerType.General)}
        >
          <img
            className={cn(styles.purpurImage, styles.generalImage)}
            src={purpurImageGeneral}
            alt="general"
          />
        </div>
      </div>
      <div className={styles.legend}>
        <div>
          Spicy
          <br />
          questions
        </div>
        <div>
          Everything
          <br />
          questions
        </div>
      </div>
    </div>
  );
};
