import { useCallback, useEffect, useRef } from 'react';

import { useCurrentDialogUsersIds, useDialogLoadApi } from 'modules/domain/dialog/hooks';
import { useWsApi } from 'modules/api';
import { ApiEventsType } from 'modules/api/dto';
import { WsDialogOperatorLockUpdatedEventDto } from 'modules/api/ws/dto';
import { useOperatorId } from 'modules/domain/auth/hooks';

export const useLockedDialogAutoSwitcher = () => {
  const operatorId = useOperatorId();
  const { animatorId, attendeeId } = useCurrentDialogUsersIds();
  const { loadNextDialog } = useDialogLoadApi();
  const { lastMessage } = useWsApi();

  const switchDialog = useCallback(() => loadNextDialog(operatorId), [loadNextDialog, operatorId]);

  const lastLockMessage = useRef<WsDialogOperatorLockUpdatedEventDto | null>(null);

  useEffect(() => {
    if (
      lastMessage?.type === ApiEventsType.DialogOperatorLockUpdated &&
      lastMessage !== lastLockMessage.current
    ) {
      const { animatorId: justLockedChatAnimatorId, clientId: justLockedChatAttendeeId } =
        lastMessage.payload;

      lastLockMessage.current = lastMessage;

      if (justLockedChatAttendeeId === attendeeId && justLockedChatAnimatorId === animatorId) {
        switchDialog();
      }
    }
  }, [animatorId, attendeeId, lastMessage, switchDialog]);
};
