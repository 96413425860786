import { Dialog } from 'modules/domain/dialog/model';
import { useUserExperimentTags } from 'modules/domain/users/hooks';
import { useIcebreakerMessagesAvailableState } from 'modules/domain/icebreaker/hooks';

export const useDialogReady = (dialog: Dialog) => {
  const attendeeExperimentTags = useUserExperimentTags(dialog.attendeeId);
  const icebreakerMessagesAvailable = useIcebreakerMessagesAvailableState(dialog.id);

  // put other dialog ready conditions here
  const dialogReady = !!(attendeeExperimentTags && icebreakerMessagesAvailable);

  return dialogReady;
};
