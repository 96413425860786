export enum IcebreakerType {
  Erotic = 'erotic',
  General = 'general',
}

export type IcebreakerMessageTemplateDto = {
  id: number;
  type: IcebreakerType;
  text: string;
};

export type IcebreakerMessageTemplatesResponseDto = {
  messages: IcebreakerMessageTemplateDto[];
};
