import { icebreakerModel } from './model';

const { actions, selectors } = icebreakerModel;

export const { setIcebreakerTemplates, setIcebreakerMessagesAvailable } = actions;
export const { getIcebreakerTemplates, getIcebreakerMessagesAvailable } = selectors;

export type { IcebreakerMessageTemplate, IcebreakerModelState } from './types';
export { IcebreakerType, IcebreakerMessagesAvailable } from './types';

export { mapIcebreakerMessageTemplatesDtoToModel } from './adapter';

export { icebreakerModel as icebreakerSlice };
