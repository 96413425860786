import cn from 'classnames';

import purpurLogo from 'images/purpur-logo-small.svg';
import { IcebreakerType } from 'modules/domain/icebreaker/model';
import { testId } from 'modules/utils';

import { IcebreakerMessageProps } from '../types';

import styles from './styles.module.scss';

export function IcebreakerMessageNew(props: IcebreakerMessageProps) {
  const { content, outgoing } = props;
  const { type, text } = content;

  return (
    <div
      {...testId(`icebreaker-message-card-${type}-new`)}
      className={cn(styles.container, { [styles.outgoing]: outgoing })}
    >
      <div className={cn(styles.card, { [styles.erotic]: type === IcebreakerType.Erotic })}>
        <p className={styles.text}>{text}</p>
        <img src={purpurLogo} className={styles.logo} />
      </div>
      <p className={styles.subtext}>
        Discuss question with the client to extend the chat and increase RR
      </p>
    </div>
  );
}
